
import request from '@/utils/request'

// 企业列表
export function fetchCompanies (query) {
  return request({
    url: '/api/mall/admin/general/get/list',
    method: 'get',
    params: query
  })
}
// 添加企业
export function setCompany (data) {
  return request({
    url: '/api/mall/admin/general/add',
    method: 'post',
    data
  })
}
// 变更企业所属员工接口
export function changeEmployeeTel (data) {
  return request({
    url: '/api/mall/admin/general/change/marketer',
    method: 'post',
    data
  })
}
// 商户列表
export function fetchMerchants (query) {
  return request({
    url: '/api/mall/admin/merchant/get/list',
    method: 'get',
    params: query
  })
}
// 获取门店列表
export function fetchStores (query) {
  return request({
    url: '/api/mall/admin/store/get/list',
    method: 'get',
    params: query
  })
}

// 删除门店接口
export function delStore (data) {
  return request({
    url: '/api/mall/admin/store/delete',
    method: 'post',
    data
  })
}

// 删除企业接口
export function delCompany (data) {
  return request({
    url: '/api/mall/admin/general/delete',
    method: 'post',
    data
  })
}
// 删除商户接口
export function delMerchant (data) {
  return request({
    url: '/api/mall/admin/merchant/delete',
    method: 'post',
    data
  })
}
// 添加/编辑商户接口
export function setMerchant (data) {
  return request({
    url: '/api/mall/admin/merchant/edit',
    method: 'post',
    data
  })
}
// 添加/编辑门店接口
export function setStore (data) {
  return request({
    url: '/api/mall/admin/store/edit',
    method: 'post',
    data
  })
}
// 获取门店详情
export function fetchStoreDetail (query) {
  return request({
    url: '/api/mall/admin/store/get/detail',
    method: 'get',
    params: query
  })
}
