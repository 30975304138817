<template>
  <div class="container">
    <table-page dataName="stores"
                :search="search"
                ref="table"
                :tabList="tabList"
                @on-success="fetchSuccess"
                :request="fetchStores">
      <template slot="button">

        <el-button type="primary"
                   v-permission="['ADMIN']"
                   class="mr-15"
                   @click="addStore">新增门店</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportStoresData">导出门店数据</el-button>
        <FileUpload action="/api/mall/admin/store/import"
                    v-permission="['ADMIN','MANAGE_CITY']"
                    @on-success="fileSuccess"
                    class="mr-15"
                    title="批量导入门店数据" />
        <el-button icon="el-icon-download"
                   v-permission="['ADMIN','MANAGE_CITY']"
                   class="mr-15"
                   @click="download">下载导入模板</el-button>
      </template>
      <el-table-column prop="merchant_num"
                       label="门店头像">
        <template slot-scope="scope">
          <TableImg :url="scope.row.head_img ? scope.row.head_img.url :''" />
        </template>

      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="门店名称">
      </el-table-column>
      <el-table-column prop="merchant_num"
                       show-overflow-tooltip
                       label="关联商户号">
      </el-table-column>
      <el-table-column prop="merchant_name"
                       show-overflow-tooltip
                       label="商户名称">
      </el-table-column>
      <el-table-column prop="store_total"
                       label="经营类目">

        <template slot-scope="scope">
          <span>{{scope.row.category.length>0 ? scope.row.category[0].name:''}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="s_address"
                       show-overflow-tooltip
                       label="营业地址">
      </el-table-column>
      <el-table-column prop="activity_total"
                       label="认证状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status ==='PENDING'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>待认证
          </span>
          <span v-else-if="scope.row.status === 'NORMAL'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>已认证
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>认证失败
          </span>
        </template>

      </el-table-column>
      <el-table-column prop="wx_merchant_num"
                       show-overflow-tooltip
                       label="微信商户号">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="setStore(scope.row.id)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     v-permission="['ADMIN']"
                     icon="el-icon-delete"
                     @click="delStore(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUpload'
import TableImg from '@/components/TableImg'
import { fetchStores, delStore } from '@/api/merchant'
export default {
  name: 'Stores',
  data () {
    return {
      exportUrl: '',
      fetchStores,
      search: [{ type: 'area' }, {
        type: 'select',
        key: 'search_type',
        value: '',
        name: '搜索类型',
        list: [
          {
            value: 'STORE_NAME',
            label: '门店名称'
          },
          {
            value: 'MERCHANT_NUM',
            label: '关联商户号'
          },
          {
            value: 'G_TEL',
            label: '归属企业手机号码'
          },
          {
            value: 'M_TEL',
            label: '所属员工手机号'
          }
        ]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NORMAL',
        label: '已认证'
      }, {
        value: 'PENDING',
        label: '未认证'
      }, {
        value: 'DENIED',
        label: '认证失败'
      }]
    }
  },
  components: {
    TablePage,
    TableImg,
    FileUpload
  },
  methods: {
    download () {
      window.open('/data/merchants/商户明细模板.xls', '_blank')
    },
    fileSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          this.$alert('导入成功')
        } else {
          this.$alert(res.data.err_msg)
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    exportStoresData () {
      window.open(this.exportUrl, '_blank')
    },
    addStore () {
      this.$router.push({
        name: 'SetStore'
      })
    },
    setStore (id) {
      this.$router.push({
        name: 'SetStore',
        query: {
          id: id
        }
      })
    },
    delStore (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delStore({
          store_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        } else {
          this.$alert(res.meta.msg)
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
